.how-it-works {
  background: #FF9D00;


  @include media("<old") {
    padding: 30px 0;
  }
  .container {
    position: relative;
  }
  .custom-section {
    &__title {
      color: #fff;
      font-size: 36px;

      @include media("<old") {
        font-size: 28px;
      }
    }
  }
}

.how-it-works-image {
  position: absolute;
  left: 150px;
  top: 150px;
  width: 445px;
  height: 445px;

  @include media("<old") {
    width: 250px;
    height: 250px;
    position: relative;
    left: 0;
    top: 0;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.how-it-works-content {
  padding: 50px 0;
  padding-top: 200px;
  height: 690px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__wrap {
    max-width: 500px;
    margin-right: 75px;
  }
  @include media("<old") {
    height: auto;
    padding: 0;
    align-items: flex-start;
    margin-right: 0;
  }
}
.how-it-works-nav {
  padding: 0;
  list-style: none;
  @include media("<1440px") {
    padding-right: 70px;
  }

  > * {
    +* {
      margin-top: 10px;
    }
  }
  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: #DDDDDD;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    transition: all .2s ease;
    text-align: left;

    @include media("<old") {
      font-size: 18px;
    }

    @include media("<568px") {
      font-size: 14px;
    }
    span {
      transition: all .2s ease;
      display: block;
    }
    &::before{
      content: "";
      width: 50px;
      height: 2px;
      border-radius: 50px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) scaleX(0);
      transition: all .2s ease;
    }
    
    &:hover {
      color: #fff;
    }
    &.active {
      color: #fff;

      &::before {
        transform: translateY(-50%) scaleX(1);
      }
      span {
        transform: translateX(75px);
      }
    }
  }

}