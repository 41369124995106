

.brands__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10px 20px 0;

  .header {
    margin: 0 0 67px 0;
    width: 100%;
  }

  .brands {
    h2 {
      text-align: left;
      font-size: 35px;
      line-height: 41px;
      letter-spacing: 0;
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 470px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .mockup-cont {
      align-self: center;
      margin-bottom: 20px;
    }

    .logo-cont {
      align-self: flex-start;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      color: $alwaysBlack;
      text-align: left;
      font-size: 16px;
      line-height: 19px;
    }

    .btn__hollow {
      align-self: flex-start;

      a {
        padding: 0;
        color: inherit;
      }
    }

    .btn__scroll {
      position: absolute;
      right: -10px;
      bottom: -30px;

      img {
        transform: rotate(180deg);
      }
    }
  }
}

.footer {
  padding-top: 0;
  min-height: unset;
  background: none;
  }
}