
.contacts-list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  margin-top: 30px;

  @include media('<1200px') {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  li {
    @include media("<1200px") {
      width: 100%;

    }
    +li {
      margin-left: 30px;
      @include media('<1200px') {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}

.contact-item {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: $alwaysWhite;
  transition: all .2s ease;
  padding: 20px 30px;
  background: $alwaysWhite;
  border-radius: $borderRadius;
  padding-left: 85px;
  position: relative;
  min-width: 375px;

  @include media("<1366px") {
    min-width: 330px;
  }

  @include media('<1200px') {
    width: 445px;
    min-width: initial;
    margin:  0 auto;
    max-width: 100%;
  }

  @include media("<568px") {
    width: 100%;
  }
  &:hover {
    color: $primaryColor;
  }
  
  &__icon {
    margin-right: 10px;
    width: 60px;
    height: 60px;
    background: #212447;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    
    img {
      width: 36px;
      height: 36px;
      object-fit: contain;
    }

  }
  &__link {
    font-size: 12px;
    display: block;
    color: $grayText;
  }

}