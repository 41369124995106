.btn {
  @include commonBtnStyles;
  @include btn-primary;

  &--border {
    @include btn-border;
  }
  &--default {
    @include btn-common;
  }
  &--second {
    @include btn-secondary;
  }
}

.ant {
  &-btn {
    font-weight: 500;
    line-height: 8px;
    text-transform: uppercase;

    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      align-items: center;

      &.ant-btn-lg {
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      @include btn-primary;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {
      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
    &-ghost {
      @include btn-border;
    }
  }
}
