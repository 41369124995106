.main-banner {
  margin: 0;
  width: 100%;
  background: transparent;
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      z-index: 1;
      width: 100%;

      li {
        +li {
          margin-left: 12px;
        }
        width: auto;
        height: auto;
        margin: 0;

        &.slick-active {
          button {
            background: $secondaryColor;
            border-color: $secondaryColor;
            opacity: 1;
          }
        }

        button {
          width: 48px;
          height: 8px;
          border: 2px solid rgba(221, 104, 46, 0.5);
          border-radius: 4px;
          padding: 0;
          transition: all .2s ease;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.banner-slide {
  $banners-path: '../../assets/images/outer-pages/main-banners/';
  position: relative;
  padding: 60px 0;
  height: 425px;
  overflow: hidden;
  box-sizing: border-box;





  @include media("<568px") {
    padding: 30px 0;
    height: 350px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center / cover;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      height: 250px;
      width: 100%;
    }
    @include media("<568px") {
      background-position: 0 center;
    }

    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: .75;
      display: none;

      @include media("<568px") {
        display: block;
      }
    }

    &--1 {
      background-image: url($banners-path + "banner_1.jpg");
    }
    &--2 {
      background-image: url($banners-path + "banner_2.jpg");
    }
  }
  &__title {
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.2;
    color: $alwaysBlack;
    position: relative;
    z-index: 5;
    max-width: 460px;
    font-family: $mainSansSerif;
    font-weight: 900;
    text-align: center;

    @include media("<568px") {
      font-size: 18px;
      max-width: 350px;
      text-shadow: 0 0 40px;
    }
  }
  &__sub-title {
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.3;
    color: $alwaysBlack;
    opacity: 0.9;
    position: relative;
    z-index: 5;
    max-width: 375px;
    font-family: $mainSansSerif;
    text-align: center;

    @include media("<568px") {
      font-size: 14px;
      max-width: 350px;
      text-shadow: 0 0 40px;
    }
  }
  &__btn {
    padding: 14px 20px;
    z-index: 10;
    min-width: 180px;
  }


  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}