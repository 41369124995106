@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.default__bg-layer {
  padding-top: 40px;

  .header {
    padding-top: 10px;
    margin: 0 20px 67px;
    height: unset;
  }

  .text__content {
    max-width: 1200px;
    margin: 20px 15px;
  }

  .footer {
    padding-top: 0;
    min-height: unset;
    background: none;
  }
}
