.contact-form {
  background: #00B147;
  border-radius: 15px;
  padding: 40px 60px;
  position: relative;

  @include media("<568px") {
    padding: 20px 15px;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
    color: #fff;


  }

  &__text {
    color: #fff;
    font-size: 16px;
  }

  &__wrap {
    display: flex;

    @include media("<568px") {
      display: block;
    }
  }

  &__item {
    width: 240px;
    margin-right: 20px;
    margin-bottom: 0;

    @include media("<568px") {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__image {
    width: 286px;
    height: 286px;
    position: absolute;
    right: 50px;
    bottom: 0;
    
    @include media("<old") {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .btn {
    width: 150px;
  }

  input,
    textarea {
      margin-top: 0 !important;
  }
}

.contact-section {
  padding: 100px 0;

  @include media("<old") {
    padding: 30px 0;
  }
}