.about-us-section {
  position: relative;
  padding-bottom: 100px;
  min-height: 400px;

  @include media('<=860px') {
    margin-top: 65px;
    padding-bottom: 30px;
    min-height: initial;
  }
  .btn {
    min-width: 210px;
    @include media("<568px") {
      width: 100%;
    }
  }
  &__title {

    &.ant-typography {
      font-size: 44px;
      text-align: left;
      @include media('<=860px') {
        font-size: 36px;
      }
      @include media("<568px") {
        font-size: 28px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }
  &__img {
    width: 450px;
    position: absolute;
    right: 150px;
    top: -50px;


    @include media("<1440px") {
      right: -50px;
    }
    @include media('<old') {
      width: 450px;
      right: -50px;
    }
    @include media('<=860px') {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
}
.about-list {
  list-style: none;
  color: $alwaysBlack;
  font-size: 18px;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 50px;

  @include media("<568px") {
    font-size: 16px;
  }
  li {
    +li {
      margin-top: 15px;
    }
    position: relative;
    padding-left: 35px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 25px;
      height: 25px;
      background: url("https://income-front-data.s3.fr-par.scw.cloud/showbet/images/star.svg") no-repeat center / contain;
      border-radius: 50%;

      @include media("<568px") {
        top: 0;
      }
    }
  }
}