.reviews-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    width: calc(33.3333% - 12px);
    margin-bottom: 24px;

    @include media("<old") {
      width: 100%;
    }
  }
}

.review-card {
  background: #FF9D00;
  border-radius: 18px;
  padding: 35px 30px;

  &:nth-of-type(2) {
    background: #00B147;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #fff;

    @include media("<1440px") {
      font-size: 28px;
    }
  }
  &__text {
    font-size: 12px;
    color: #fff;
    line-height: 1.4;
    font-style: italic;
    max-width: 240px;

  }
}