@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

* {
  font-family: $mainSansSerif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: $mainSansSerif;


  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border-radius: $borderRadius;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: $defaultShadow;
      resize: none;
      border: 1px solid transparent;

      &:focus,
      :active {
        outline: none;
        border-color: $borderBlue;
      }
    }
  }
}
