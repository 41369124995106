.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: $primaryColor;
  color: #fff;

  &__info {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
  }
  &__wrapper {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    position: relative;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    &-item {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 20px 0 0;

      &:last-child {
        margin-right: 0;
      }
      a {
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

  @include maxWidth(1074px) {
    height: fit-content;
    &__wrapper {
      padding: 26px 32px 25px;
      flex-direction: column;
    }
    &__menu {
      height: fit-content;
      margin: 0 0 20px;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &-item {
        margin: 0 15px 10px 0;
      }
    }
    &__info {
      order: 2;
      margin-top: 10px;
    }
  }

  @include maxWidth(500px) {
    &__wrapper {
      padding: 26px 16px 25px;
    }
    &__menu {
      flex-direction: column;
      flex-wrap: nowrap;
      &-item {
        margin: 0 0 10px 0;
      }
    }
  }
}