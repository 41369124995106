.commissions-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    width: calc(50% - 12px);
    margin-bottom: 24px;

    @include media("<568px") {
      width: 100%;
    }
  }
}

.commission-card {
  background: #00B147;
  border-radius: 18px;
  padding: 25px 35px;
  position: relative;
  height: 285px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__icon {
    width: 240px;
    height: 240px;
    position: absolute;
    right: 30px;
    bottom: 0px;

    @include media("<old") {
      width: 120px;
      height: 120px;
    }
    @include media("<568px") {
      width: 150px;
      height: 150px;
      right: -30px;
      bottom: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: -1;
      pointer-events: none;
      user-select: none;
    }
  }
  &__title {
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
    max-width: 250px;
    color: #fff;
    position: relative;
    z-index: 2;

    @include media("<1440px") {
      font-size: 28px;
    }
    @include media("<old") {
      font-size: 26px;
      text-shadow: 0 0 30px rgba(0,0,0, .5);

    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.4;
    max-width: 250px;
    color: #fff;
    position: relative;
    z-index: 2;
    @include media("<old") {
      text-shadow: 0 0 30px rgba(0,0,0, .5);

    }
  }

  .btn {
    margin-top: auto;
    position: relative;
    z-index: 2;
  }
}

.commission-page {
  &__card {
    width: 770px;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 80px;
    border-radius: 10px;
    padding: 20px 30px;
    background-color: $alwaysWhite;
    color: $alwaysBlack;
    box-shadow: $defaultShadow;

    @include media("<568px") {
      padding: 20px 10px;
    }
  }
  &__icon {
    text-align: center;
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 240px;
    height: 240px;

    img {
      max-height: 100%;
      object-fit: contain;
    }
  }
  &__list {
    list-style: none;
    padding: 0;

    li {
      +li{
        margin-top: 10px;
      }

      position: relative;
      padding-left: 20px;
      font-size: 16px;
      line-height: 1.5;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primaryColor;
      }
    }
  }

  .btn {
    width: 210px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
  }
}