.nav-cards {
  margin-top: 30px;

  @include media("<568px") {
    margin-top: 30px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    width: 50%;
    padding: 0 14px;
    padding-bottom: 28px;

    @include media("<=860px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.nav-card {
  position: relative;
  transition: all .2s ease;
  box-shadow: $defaultShadow;
  border-radius: 18px;
  background: #00B147;

  &__info {
    height: 285px;
    padding: 25px 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 2;

  }
  &__title {
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    max-width: 350px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 10px;

    @include media("<old") {
      text-shadow: 0 0 40px rgba(0,0, 0, .2);
    }
  }
  &__text {
    font-size: 12px;
    line-height: 1.6;
    font-style: italic;
    max-width: 250px;
    color: #fff;

    @include media("<old") {
      text-shadow: 0 0 40px rgba(0,0, 0, .2);
    }
  }
  &__icon {
    margin-left: auto;
    position: absolute;
    right: 20px;
    bottom: 20px;
    max-width: 225px;
    max-height: 225px;

    @include media("<old") {
      max-width: 150px;
      max-height: 150px;
    }

    @include media("<568px") {
      right: -30px;
      bottom: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: -1;
      cursor: default;
      pointer-events: none;
      user-select: none;
    }
  }

  .btn {
    margin-top: auto;
    width: auto;
  }

  &:hover {
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.35);

  }
}
