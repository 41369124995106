
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;
  list-style: none;

  &__item {
    width: calc(100% / 3);
    padding: 0 15px;
    padding-bottom: 30px;

    @include media('<=860px') {
      width: 100%;
    }

    &:first-child {
      .benefit-card {
        &__icon {
          top: auto;
          bottom: 0;
          transform: translateY(0);
        }
      }
    }
  }
}

.benefit-card {
  background: #00B147;
  border-radius: 18px;
  height: 420px;
  color: #fff;
  position: relative;
  font-size: 14px;
  line-height: 1.3;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;

  @include media("<old") {
    text-align: center;
  }

  @include media("<568px") {
    height: auto;
  }
  &__title {
    margin: 0;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 700;
    color: #fff;

    @include media("<1440px") {
      font-size: 18px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.6;
    color: #fff;
    margin: 0;
  }
  &__icon {
    width: 232px;
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;

    @include media("<568px") {
      width: 150px;
      height: 150px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}